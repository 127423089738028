import { Balance, Profile, Value, ValueSeries, ViewParams } from "../types";
import { API_ENDPOINT } from "./auth";
import { getProfileFilter, YEAR } from "./common";

export async function GetBalance(params: ViewParams): Promise<Balance> {
    const url = API_ENDPOINT + "/auth/balance/summary" + (params.profile !== "global" ? "?profile=" + params.profile : "");
    const response = await fetch(url, {
        credentials: 'include',
    }).then(response => response.json());
    const data = await response;
    return data && data.distribution && {
        balance: data.balance,
        balanceCash: data.balanceCash,
        balanceP2: data.balanceP2,
        balanceP3: data.balance - data.balanceNoPension - data.balanceP2,
        balanceNoPension: data.balanceNoPension,
        balanceNoPP: data.balanceNoPP,
        property: data.balanceProperty,
        distribution: data.distribution.map((item: { destination: any; amount: any; class: any, }) => {
            return {
                name: item.destination,
                value: item.amount,
                deposits: 0,
                class: item.class,
            };
        })
    } || {};
}

export function getLastYearIncrease(profile: string): number {
    // fixme: get from backend
    if (profile == "global") {
        return 120000 + 30000;
    } else if (profile == "Claudia" ) {
        return 60000 + 30000;
    } else {
        return 60000;
    }
}

export function getPensionProjection(profile: Profile, balance: number): { years: number, value: number, finalBalance: number, roiPercentage: number, yearlySavings: number, estimatedYearBalance: ValueSeries } {
    const value = Math.min(Math.round(balance / profile.target * 100), 100);
    const roiPercentage = 4/100;
    const yearlySavings = getLastYearIncrease(profile.name);
    const now = Date.now();
    const currentYear = new Date().getFullYear();

    var estimatedBalance = balance + 0; //copy
    var estimatedYears = 0;
    var estimatedYearBalance = Array<Value>();

    while (estimatedBalance < profile.target) {
        estimatedBalance = Math.round(estimatedBalance * (1 + roiPercentage) + yearlySavings);
        estimatedYears ++;
        estimatedYearBalance.push({
            x: now + estimatedYears * YEAR,
            estimatedValue: estimatedBalance,
            name: (currentYear + estimatedYears).toString(),
        })
    }

    return {
        years: estimatedYears,
        value: value,
        finalBalance: estimatedBalance,
        roiPercentage: roiPercentage,
        yearlySavings: yearlySavings,
        estimatedYearBalance: estimatedYearBalance,
    }
}


export async function GetBalanceHistory(params: ViewParams): Promise<ValueSeries> {
    const url = API_ENDPOINT + "/auth/balance" + (params.profile !== "global" ? "?profile=" + params.profile : "");
    const response = await fetch(url, {
        credentials: 'include',
    }).then(response => response.json());
    const data = await response;
    return data && data.balance && data.balance.filter(getProfileFilter(params)).map((item: { date: string, amount: number }) => {
        return {
            x: Date.parse(item?.date)/1000,
            name: item?.date,
            value: item?.amount,
            ...item
        };
    }) || {};
}
