import React from "react";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { GetSavingsHistory, GetSavingsHistoryGroupped } from "../../api/savings";
import { GrouppedValueSeries, Profile, ValueSeries, ViewParams } from "../../types";
import LineChartCard from "../common/LineChartCard";
import ListCard from "../common/ListCard";
import { getCurrentProfile } from "../../api/profiles";
import { GetIncomeHistory, GetIncomeHistoryGroupped } from "../../api/income";
import { useAsync } from "react-use";
import AsyncView from "../common/AsyncView";
import InfoCard from "../common/InfoCard";
import { getValueSeriesSum } from "../../api/common";
import { Amount } from "../common/fragments";
import ProgressCircle from "../common/ProgressCircle";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
    }),
);

function getAmountSummary(data: GrouppedValueSeries): { y2d: number, all: number } {
    return {
        y2d: getValueSeriesSum(data.groups.get("y2d") || []),
        all: getValueSeriesSum(data.groups.get("all") || []),
    }
}
function AmountInfoCard({ incomeData, title, privacy }: { incomeData: GrouppedValueSeries, title: string, privacy?: boolean }) {
    const amountSummary = getAmountSummary(incomeData)
    return <InfoCard title={title}>
        <p>
            Amount this year: <Amount value={amountSummary.y2d} privacy={privacy} />
        </p>
        <p>
            Total amount: <Amount value={amountSummary.all} privacy={privacy} />
        </p>
    </InfoCard>
}

function Savings({ value, profile, privacy }: { value: ValueSeries, profile: Profile, privacy: boolean }) {
    const data = GetSavingsHistoryGroupped(value) || {};

    return <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
            <LineChartCard data={data} title="Savings" profile={profile} privacy={privacy} />
        </Grid>
        <Grid item xs={12} lg={4}>
            <ListCard data={data.raw || []} title="Savings History" privacy={privacy} />
        </Grid>
        <Grid item xs={12} lg={4}>
            <AmountInfoCard incomeData={data} title="Savings Summary" privacy={privacy} />
        </Grid>
    </Grid>;
}

function Income({ value, profile, privacy }: { value: ValueSeries, profile: Profile, privacy: boolean }) {
    const incomeData = GetIncomeHistoryGroupped(value) || {};
    return <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
            <LineChartCard data={incomeData} title="Income" profile={profile} privacy={privacy} />
        </Grid>
        <Grid item xs={12} lg={4}>
            <ListCard data={incomeData.raw || []} title="Income History" privacy={privacy} />
        </Grid>
        <Grid item xs={12} lg={4}>
            <AmountInfoCard incomeData={incomeData} title="Income Summary" privacy={privacy} />
        </Grid>
    </Grid>;
}

function SavingsRate({ savings, income, profile, privacy }: { savings: ValueSeries, income: ValueSeries, profile: Profile, privacy: boolean }) {
    const savingsData = GetSavingsHistoryGroupped(savings) || {};
    const incomeData = GetIncomeHistoryGroupped(income) || {};
    const savingsSummary = getAmountSummary(savingsData)
    const incomeSummary = getAmountSummary(incomeData)
    const rateSummary = {
        y2d: incomeSummary.y2d > 0 ? savingsSummary.y2d / incomeSummary.y2d * 100 : 0,
        all: incomeSummary.all > 0 ? savingsSummary.all / incomeSummary.all * 100 : 0,
    }

    return <Grid container spacing={3}>
        <Grid item xs={6} lg={2}>
            <InfoCard title="Savings Rate y2d">
                <ProgressCircle value={rateSummary.y2d} color={profile.color} />
            </InfoCard>
        </Grid>
        <Grid item xs={6} lg={2}>
            <InfoCard title="Savings Rate total">
                <ProgressCircle value={rateSummary.all} color={profile.color} />
            </InfoCard>
        </Grid>
    </Grid>;
}

export default function SavingsPage(props: ViewParams) {
    const classes = useStyles();
    const currentProfile = getCurrentProfile(props.profile);

    const getSavings = () => GetSavingsHistory(props)
    const getIncome = () => GetIncomeHistory(props)
    const savingsProps = useAsync(getSavings, []);
    const incomeProps = useAsync(getIncome, []);

    const allProps = useAsync(async () => await Promise.all([getSavings, getIncome]));

    return (
        <div className={classes.root}>
            <AsyncView {...allProps}>
                {savingsProps.value && incomeProps.value && <SavingsRate savings={savingsProps.value} income={incomeProps.value} profile={currentProfile} privacy={props.privacy} />}
            </AsyncView>
            <AsyncView {...savingsProps}>
                {savingsProps.value && <Savings value={savingsProps.value} profile={currentProfile} privacy={props.privacy} />}
            </AsyncView>
            <AsyncView {...incomeProps}>
                {incomeProps.value && <Income value={incomeProps.value} profile={currentProfile} privacy={props.privacy} />}
            </AsyncView>
        </div>
    )
}
