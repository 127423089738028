import React, { ReactNode } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import Icon from '@material-ui/core/Icon';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { NavLink } from 'react-router-dom';

const drawerWidth = 200;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        active: {
            backgroundColor: theme.palette.action.selected,
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
        },
    }),
);

export default function DesktopMenu({ children }: { children: ReactNode }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <div className={classes.toolbar}>
                   <h1>
                   <img src="logo192.png" width={40} style={{verticalAlign: "top", marginRight: 10}} />
                   FIRE
                   </h1>
                </div>
                <Divider />
                <List>
                    <ListItem button key="home" component={NavLink} to="/" exact activeClassName={classes.active}>
                        <ListItemIcon><HomeIcon /></ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button key="savings" component={NavLink} to="/saverate" activeClassName={classes.active}>
                        <ListItemIcon>
                            <Icon className="fa fa-piggy-bank" style={{ fontSize: 18, overflow: "visible" }} />
                        </ListItemIcon>
                        <ListItemText primary="Savings" />
                    </ListItem>
                    <ListItem button key="progress" component={NavLink} to="/progress" activeClassName={classes.active}>
                        <ListItemIcon>
                            <TrendingUpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Progress" />
                    </ListItem>
                </List>
            </Drawer>
            <main className={classes.content}>
                {children}
            </main>
        </div>
    );
}
