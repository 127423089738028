import { PerformanceSeries, ValueSeries, Value, GrouppedValueSeries, GrouppedPerformanceSeries, AccountSeries, Account, Balance } from "../types";
import { getValueSeriesInterval, getValueSeriesSum, getYearAgo, getYearStart, YEAR } from "./common";

export function GetAllYears(balance: ValueSeries): Set<number> {
    const years = new Set<number>();
    balance.map(item => { var year = (new Date(item.x * 1000)).getFullYear(); years.add(year) })
    return years;
}

function getProfileNames(balance: ValueSeries): Set<string> {
    const profiles = new Set<string>();
    balance.map(item => { profiles.add(item.profile || '') })
    return profiles;
}

export function getAccountNames(balance: ValueSeries): Set<string> {
    const accounts = new Set<string>();
    balance.map(item => { accounts.add(item.destination || '') })
    return accounts;
}

export function getAccountNamesByClass(balance: ValueSeries, className: string): Array<string> {
    const accounts = new Set<string>();
    balance.map(item => { if (item.class === className) { accounts.add(item.destination || '') } })
    return Array.from(accounts);
}

function getAccountBalance(accountName: string, balance: ValueSeries, profile: string): number {
    var accountValue = 0;
    balance.some(item => {
        if (((item.destination === accountName) || item.class === accountName) && (item.profile == profile)) {
            accountValue = item.value || 0;
            return true;
        }
    });
    return accountValue;
}

export function getAggregatedAccountBalance(accountName: string, balance: ValueSeries, profile: string): number {
    var accountBalance = 0;
    if (profile === "global") {
        Array.from(getProfileNames(balance)).map(item => { accountBalance += getAccountBalance(accountName, balance, item) })
    } else {
        accountBalance = getAccountBalance(accountName, balance, profile)
    }
    return accountBalance;
}

export function getAccountSavings(accountName: string, savings: ValueSeries): ValueSeries {
    if (!savings) {
        return [];
    }
    return savings.filter(item => ((item.destination === accountName) || (item.class === accountName)))
}

export function getAccountDeposits(savings: ValueSeries): ValueSeries {
    return savings.filter(item => ((item.value || 0) > 0))
}

export function getAccountWithdrawals(savings: ValueSeries): ValueSeries {
    return savings.filter(item => ((item.value || 0) < 0))
}

function getAccountAccumulatedDeposits(savings: ValueSeries): ValueSeries {
    var depositValue = 0;
    var deposits = Array<Value>();

    savings.sort((a, b) => (a.x - b.x));
    deposits = savings.map(item => {
        depositValue += item.value || 0;
        return {
            x: item.x,
            value: 0 + depositValue, // make a copy not a reference
            name: item.name,
            destination: item.destination,
        }
    })
    return deposits
}

function GetPerformanceContribution(savings: ValueSeries, balance: ValueSeries, profile: string, originalBalance: ValueSeries): {
    performance: PerformanceSeries,
    accounts: GrouppedValueSeries,
    deposits: GrouppedValueSeries,
    savings: GrouppedValueSeries,
    originalBalance?: number, balance: Balance
} {
    if (!balance || !balance.sort) {
        balance = [];
    }
    originalBalance = originalBalance || [];
    balance.sort((a, b) => (a.class || "Unknown").localeCompare(b.class || "Unknown"))
    const accountNames = getAccountNames(balance);
    const accountDetails = new Map<string, ValueSeries>();
    const depositDetails = new Map<string, ValueSeries>();
    const savingsDetails = new Map<string, ValueSeries>();
    var totalBalance = 0;
    var totalBalanceNoPension = 0;
    var totalBalanceNoPP = 0;
    var excluded = ["Current Account", "Savings Account", "Lent", "Property", "Home", "Modrangu", "Car", "IBKR Cash", "Pillar 2", "GYG"];

    balance.sort((a, b) => -(a.x - b.x));
    originalBalance.sort((a, b) => -(a.x - b.x));

    var performance = Array.from(accountNames).map((account: string) => {
        var accountSavings = getAccountSavings(account, savings);
        var deposits = getAccountDeposits(accountSavings);
        var withdrawals = getAccountWithdrawals(accountSavings);
        var value = getAggregatedAccountBalance(account, balance, profile)
        var originalValue = getAggregatedAccountBalance(account, originalBalance, profile);
        var depositValue = getValueSeriesSum(deposits)
        var withdrawalValue = getValueSeriesSum(withdrawals)
        var balanceHistory = balance.filter(item => item.destination == account)
        balanceHistory.sort((a, b) => (a.x - b.x));

        if (excluded.indexOf(account) == -1) {
            accountDetails.set(account, balanceHistory);
            depositDetails.set(account, getAccountAccumulatedDeposits(deposits));
            savingsDetails.set(account, getAccountAccumulatedDeposits(accountSavings));
        }

        totalBalance += value;
        if (balanceHistory[0].class !== "Pension") {
            totalBalanceNoPension += value;
            if (balanceHistory[0].class !== "Property") {
                totalBalanceNoPP += value;
            }
        }

        var compareValue = Math.max(value, - withdrawalValue);
        if (compareValue > 0) {
            return {
                "name": account,
                class: balanceHistory[0].class || '',
                "value": Math.round((value - originalValue - depositValue - withdrawalValue) / compareValue * 100),
                deposits: depositValue,
                withdrawals: withdrawalValue,
                balance: value,
            };
        } else {
            return {
                "name": account,
                class: balanceHistory[0].class || '',
                "value": 0,
                deposits: depositValue,
                withdrawals: withdrawalValue,
                balance: value,
            }
        }
    });
    performance.sort((a, b) => (a.value - b.value));

    const sortedAccountDetails = new Map<string, ValueSeries>();
    const sortedDepositDetails = new Map<string, ValueSeries>();
    const sortedSavingsDetails = new Map<string, ValueSeries>();
    performance = performance.filter(item => (accountDetails.get(item.name) || []).length > 0)
    performance.map(item => {
        sortedAccountDetails.set(item.name, accountDetails.get(item.name) || [])
        sortedDepositDetails.set(item.name, depositDetails.get(item.name) || [])
        sortedSavingsDetails.set(item.name, savingsDetails.get(item.name) || [])
    })
    return {
        performance: performance.filter(item => originalBalance.length == 0 || item.balance != 0),
        accounts: {
            groups: sortedAccountDetails,
            raw: savings,
        },
        deposits: {
            groups: sortedDepositDetails,
            raw: savings,
        },
        savings: {
            groups: sortedSavingsDetails,
            raw: savings,
        },
        balance: {
            balance: totalBalance,
            balanceNoPension: totalBalanceNoPension,
            balanceNoPP: totalBalanceNoPP,
            distribution: [],
        }
    }
}

function mergeDepAcc(deposits: GrouppedValueSeries, accounts: GrouppedValueSeries, profile: string): GrouppedValueSeries {
    const result: GrouppedValueSeries = {
        groups: new Map<string, ValueSeries>(),
        raw: []
    };
    deposits.groups.forEach((value: ValueSeries, key: string) => {
        const account = (accounts.groups.get(key) || []);
        account.sort((a, b) => -(a.x - b.x));
        const newValues: ValueSeries = [];
        value.map((v) => {
            const balanceSoFar = account.filter(a => a.x < v.x)
            v.balance = getAggregatedAccountBalance(key, balanceSoFar, profile)
            newValues.push(v)
        })
        const lastValue = value[value.length - 1];
        const extraBalance = account.filter(a => a.x > lastValue.x)
        extraBalance.map((v) => {
            const balanceSoFar = account.filter(a => a.x <= v.x)
            const extraValue = {
                x: v.x,
                name: lastValue.name,
                destination: lastValue.destination,
                value: lastValue.value,
                balance: getAggregatedAccountBalance(key, balanceSoFar, profile)
            }
            newValues.push(extraValue)
        })
        newValues.sort((a, b) => (a.x - b.x))
        result.groups.set(key, newValues);
    })
    return result
}

export function GetGrouppedPerformanceContribution(savings: ValueSeries, balance: ValueSeries, profile: string): { performance: GrouppedPerformanceSeries, contribution: AccountSeries, loss: AccountSeries, depositsAndAccounts: GrouppedValueSeries, deposits: GrouppedValueSeries, balance: Balance, accounts: GrouppedValueSeries, } {
    const result = new Map<string, PerformanceSeries>();

    const perf = GetPerformanceContribution(savings, balance, profile, [])
    result.set("all time", perf.performance)

    // fixme
    const now = Date.now() / 1000
    const yearStart = getYearStart();
    const fiveYearsAgo = getYearAgo() - 4 * YEAR;
    const tenYearsAgo = getYearAgo() - 9 * YEAR;
    var partialSavings: ValueSeries
    var partialBalance: ValueSeries
    partialSavings = getValueSeriesInterval(savings, yearStart, now)
    partialBalance = getValueSeriesInterval(balance, 0, yearStart)
    result.set("y2d", GetPerformanceContribution(partialSavings, balance, profile, partialBalance).performance);

    partialSavings = getValueSeriesInterval(savings, fiveYearsAgo, now)
    partialBalance = getValueSeriesInterval(balance, 0, fiveYearsAgo)
    result.set("5y", GetPerformanceContribution(partialSavings, balance, profile, partialBalance).performance);

    partialSavings = getValueSeriesInterval(savings, tenYearsAgo, now)
    partialBalance = getValueSeriesInterval(balance, 0, tenYearsAgo)
    result.set("10y", GetPerformanceContribution(partialSavings, balance, profile, partialBalance).performance);

    const computed: Array<Account> = perf.performance.map(item => {
        const itemContribution = item.balance - item.deposits - item.withdrawals;
        return {
            name: item.name,
            value: itemContribution,
            class: item.class,
        }
    });
    const contribution: Array<Account> = computed.filter(a => a.value >= 0)
    const loss: Array<Account> = computed.filter(a => a.value < 0)

    return {
        performance: result,
        balance: perf.balance,
        contribution: contribution,
        loss: loss,
        accounts: perf.accounts,
        deposits: perf.deposits,
        depositsAndAccounts: mergeDepAcc(perf.savings, perf.accounts, profile),
    }
}
