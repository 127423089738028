import React from 'react';
import './App.css';
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
import DesktopMenu from './components/navigation/DesktopMenu';
import MobileMenu from './components/navigation/MobileMenu';
import HomePage from './components/pages/HomePage';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import SavingsPage from './components/pages/SavingsPage';
import ProgressPage from './components/pages/ProgressPage';
import { InteractiveViewParams } from './types';
import ProfileChanger from './components/ProfileChanger';
import CheckAuthentication from './components/CheckAuth';
import PrivacyToggle from './components/PrivacyToggle';
import { Divider } from '@material-ui/core';


function Content({ profile, date, privacy, setDate }: InteractiveViewParams) {
  // fixme resolve async stuff here, pass values down stream
  return (
    <>
      <Route exact path="/" component={() => <HomePage profile={profile} date={date} privacy={privacy} setDate={setDate} />} />
      <Route path="/saverate" component={() => <SavingsPage profile={profile} date={date} privacy={privacy} />} />
      <Route path="/progress" component={() => <ProgressPage profile={profile} date={date} privacy={privacy} />} />
    </>
  )
}
function App() {
  const [profile, setProfileValue] = React.useState("global");
  const [date, setDateValue] = React.useState(Date());
  const [privacy, setPrivacyValue] = React.useState<boolean>(false);

  return (
    <div className="App">
      <CheckAuthentication />
      <Router>
        <BrowserView>
          <ProfileChanger callback={setProfileValue} profile={profile} />
          <DesktopMenu>
            <Content profile={profile} date={date} privacy={privacy} setDate={setDateValue} />
            <PrivacyToggle privacy={privacy} privacyCallback={setPrivacyValue} />
          </DesktopMenu>
        </BrowserView>
        <MobileView>
          <ProfileChanger callback={setProfileValue} profile={profile} />
          <MobileMenu>
            <Content profile={profile} date={date} privacy={privacy} setDate={setDateValue} />
            <PrivacyToggle privacy={privacy} privacyCallback={setPrivacyValue} />
            <Divider style={{height: 50}} />
          </MobileMenu>
        </MobileView>
      </Router>
    </div>
  );
}

export default App;
